<template>
    <div class="CompanyEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules" size="small">
                <el-form-item label="企业名称" prop="name">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="简称">
                    <el-input v-model="form.shortName" />
                </el-form-item>
                <el-form-item label="公司电话" prop="telephone">
                    <el-input v-model="form.telephone" />
                </el-form-item>
                <el-form-item label="公司地址" prop="address">
                    <el-input v-model="form.address" />
                </el-form-item>
                <el-form-item label="公司介绍">
                    <el-input v-model="form.introduce" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'CompanyCreate',
    data() {
        return {
            company: {},
            url: '/system/company/createExtend',
            form: {},
            rules: {
                name: [{ required: true, trigger: 'blur', message: '请输入企业名称' }],
                telephone: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '电话格式为11位数字，固定电话格式为区号+电话号，例：01011111111',
                        pattern: /\d{10,12}/,
                    },
                ],
                address: [{ required: true, trigger: 'blur', message: '请输入联系地址' }],
            },
        };
    },
    methods: {
        handleSave() {
            this.$refs.form.validate((valid) => {
                if (!valid) return;

                const _p = {
                    names: this.form.name || ' ',
                    shortNames: this.form.shortName || ' ',
                    telephones: this.form.telephone || ' ',
                    addresses: this.form.address || ' ',
                    introduces: this.form.introduce || ' ',
                };

                UrlUtils.PostRemote(this, this.url, _p, null, () => {
                    this.$message.success('保存成功');
                    this.goBackAndReload();
                });
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
